// If styling updates are made to this component, the same should be made to AddressAutoCompleteField
import classNames from "classnames"
import { useField } from "formik"
import React, { useState, useRef, useEffect } from "react"

import InputError from "./InputError"
import { Color } from "../../../../constants/V2/color"
import ChevronIcon from "../Icons/ChevronIcon"
import Typography from "../Typography"

export const DropdownStyles =
  "absolute z-10 w-full rounded-10 rounded-t-none border border-t-0 border-blue bg-white pb-8 shadow-md"

export const DropdownItemStyles = "mx-8 cursor-pointer rounded-[3px]"

export interface SelectOption {
  label: string
  value: string | number
}

export interface Props {
  id: string
  name: string
  className?: string
  disabled?: boolean
  required?: boolean
  placeholder: string
  onFocus?: () => void
  options: SelectOption[]
  color?: Color.White | Color.Charcoal
  ["data-test-id"]?: string
}

const DropdownInput = ({
  id,
  name,
  onFocus,
  options,
  className,
  placeholder,
  color = Color.Charcoal,
  disabled = false,
  required = false,
  ...props
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [focusedIndex, setFocusedIndex] = useState<number>(-1)
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [focus, setFocus] = useState(false)
  const [field, meta, helpers] = useField(name)

  const hasError = meta.touched && meta.error

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Close dropdown if it loses focus
  const handleBlur = () => {
    setTimeout(() => {
      if (!dropdownRef.current?.contains(document.activeElement)) {
        setIsOpen(false)
        setFocus(false)
        helpers.setTouched(true)
      }
    }, 10)
  }

  const toggleDropdown = () => setIsOpen((prev) => !prev)

  const handleOptionSelect = (value: string | number, index: number) => {
    helpers.setValue(value)
    setIsOpen(false)
    setFocusedIndex(-1)
    setSelectedIndex(index)
    setFocus(false)
  }

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLButtonElement | HTMLLIElement>
  ) => {
    if (!isOpen) return

    if (e.key === "ArrowDown") {
      setFocusedIndex((prev) => (prev + 1) % options.length)
    } else if (e.key === "ArrowUp") {
      setFocusedIndex((prev) => (prev - 1 + options.length) % options.length)
    } else if (e.key === "Enter" && focusedIndex >= 0) {
      handleOptionSelect(options[focusedIndex].value, focusedIndex)
    } else if (e.key === "Escape") {
      setIsOpen(false)
    }
  }

  return (
    <div
      className={classNames("w-full", { "opacity-50": disabled }, className)}
      onBlur={handleBlur}
      tabIndex={-1}
      ref={dropdownRef}
    >
      <div className="relative w-full">
        <label
          htmlFor={id}
          className={classNames(
            "pointer-events-none absolute left-16 flex gap-8 transition-all duration-200 ease-linear",
            field.value ? "top-10" : "top-20"
          )}
        >
          <Typography
            weight={field.value ? "medium" : "book"}
            font="grotesk"
            color={color}
            text={required ? `${placeholder} *` : placeholder}
            size={field.value ? "subscript-sm" : "body-sm"}
            className={classNames("transition-all duration-200")}
          />
        </label>
        <input
          id={id}
          {...field}
          {...props}
          type="hidden"
          value={field.value}
          onChange={(e) => {
            const selectedOption = options.find(
              (option) => option.value === e.target.value
            )
            if (selectedOption) {
              setSelectedIndex(options.indexOf(selectedOption))
              helpers.setValue(selectedOption.value)
            }
          }}
        />
        <button
          disabled={disabled}
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          className={classNames(
            "h-[57px] w-full rounded-10 border border-charcoal/20 bg-white px-16 py-12 pt-24 text-left hover:border-charcoal",
            { "rounded-b-none border-b-0 !border-blue": isOpen },
            { "border-orange": hasError }
          )}
          onClick={(e) => {
            e.preventDefault()
            toggleDropdown()
          }}
          onFocus={() => {
            setFocus(true)
            if (onFocus) {
              onFocus()
            }
          }}
          onKeyDown={handleKeyDown}
        >
          <Typography
            weight="book"
            font="grotesk"
            color={color}
            text={
              field.value
                ? `${options.find((option) => option.value === field.value)?.label}`
                : " "
            }
            size="body-sm"
            className={classNames("mt-[7px] transition-all duration-200")}
          />

          <span
            className={classNames(
              "pointer-events-none absolute right-16 top-16 duration-200 ease-linear",
              { "rotate-180": focus }
            )}
          >
            <ChevronIcon />
          </span>
        </button>

        {isOpen && (
          <ul role="listbox" className={DropdownStyles}>
            {options.map((option, index) => (
              <React.Fragment key={option.value}>
                <div
                  className={classNames(
                    "mx-8 border-t-[1px]",
                    focusedIndex === index ||
                      focusedIndex === index - 1 ||
                      selectedIndex === index ||
                      selectedIndex === index - 1
                      ? "border-transparent"
                      : "border-charcoal/20"
                  )}
                />
                <li
                  role="option"
                  tabIndex={0}
                  aria-selected={field.value === option.value}
                  className={classNames(
                    DropdownItemStyles,
                    "px-10 py-12",
                    { "bg-blue/20": field.value === option.value },
                    { "bg-blue/30": focusedIndex === index }
                  )}
                  onClick={() => handleOptionSelect(option.value, index)}
                  onKeyDown={handleKeyDown}
                  onMouseEnter={() => setFocusedIndex(index)}
                >
                  <Typography
                    weight="book"
                    font="grotesk"
                    color={color}
                    text={option.label}
                    size="body-sm"
                  />
                </li>
              </React.Fragment>
            ))}
          </ul>
        )}
      </div>
      {hasError ? (
        <InputError
          error={`${meta.error}`}
          data-test-id={`input-error-${props["data-test-id"]}`}
        />
      ) : null}
    </div>
  )
}

export default DropdownInput
