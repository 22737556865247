import classNames from "classnames"
import { useField } from "formik"
import React, { useState, useEffect } from "react"

import InputError from "./InputError"
import { Color } from "../../../../constants/V2/color"
import Typography from "../Typography"

import { getTextColorClass } from "@utils/V2/color"

// TODO: Whole input, styling and props is 95% the same as Custom input. Look into refactoring to one code
export interface Props {
  id: string
  name: string
  disabled?: boolean
  required?: boolean
  className?: string
  label: string
  placeholder?: string
  color?: Color.White | Color.Charcoal
  onFocus?: () => void
  ["data-test-id"]?: string
}

const PhoneNumberInput = ({
  id,
  name,
  onFocus,
  className,
  label,
  placeholder,
  color = Color.Charcoal,
  disabled = false,
  required = false,
  ...props
}: Props) => {
  const [field, meta, helpers] = useField(id)
  const firstChar = field.value?.charAt(0)

  const [focus, setFocus] = useState(false)

  const handleChange = (value: string) => {
    const phoneNumber = value.trim()
    const isNumberValid = !!phoneNumber.match(/^((0)|(27)|(\+27))\d{9}$/)
    if (value) {
      if (isNumberValid && value.length >= 10) {
        if (phoneNumber.charAt(0) === "+") {
          helpers.setValue(phoneNumber.substring(1))
        } else if (phoneNumber.charAt(0) === "0") {
          helpers.setValue(`27${phoneNumber.substring(1)}`)
        } else {
          helpers.setValue(phoneNumber)
        }
      } else {
        helpers.setValue(value.trim())
      }
    } else {
      helpers.setValue("")
    }
  }

  const hasValue = !!field.value
  const hasError = meta.touched && meta.error
  const hasValueOrFocus = hasValue || focus

  useEffect(() => {
    helpers.setTouched(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classNames("w-full", className)}>
      <div className="relative w-full rounded-10">
        <label
          htmlFor={id}
          className={classNames(
            "absolute left-16 transition-all duration-200 ease-linear",
            {
              "top-10": hasValueOrFocus,
            },
            {
              "top-20": !hasValueOrFocus,
            }
          )}
        >
          <Typography
            weight={hasValueOrFocus ? "medium" : "book"}
            font="grotesk"
            color={color}
            text={required ? `${label} *` : label}
            size={hasValueOrFocus ? "subscript-sm" : "body-sm"}
            className={classNames("transition-all duration-200")}
          />
        </label>

        {hasValue && firstChar === "2" ? (
          <Typography
            text="+"
            weight="book"
            font="grotesk"
            size="body-sm"
            color={Color.Black}
            className="absolute left-16 top-[25px]"
          />
        ) : null}

        <input
          id={id}
          {...field}
          {...props}
          name={name}
          type="text"
          maxLength={13}
          value={field.value}
          disabled={disabled}
          placeholder={hasValueOrFocus && placeholder ? placeholder : undefined}
          onBlurCapture={() => setFocus(false)}
          onChange={(event) => handleChange(event.target.value)}
          className={classNames(
            "w-full rounded-10 border bg-transparent px-16 pb-10 pt-[28px] text-body-sm focus:border-blue",
            getTextColorClass(color),
            {
              "pl-24": hasValue && firstChar === "2",
            },
            { "border-orange": hasError },
            {
              "border-charcoal/20 hover:border-charcoal":
                color === Color.Charcoal && !hasError,
            },
            {
              "border-white/20 hover:border-white":
                color === Color.White && !hasError,
            }
          )}
          onFocus={() => {
            setFocus(true)
            if (onFocus) {
              onFocus()
            }
          }}
        />
      </div>

      {hasError ? (
        <InputError
          error={`${meta.error}`}
          data-test-id={`input-error-${props["data-test-id"]}`}
        />
      ) : null}
    </div>
  )
}

export default PhoneNumberInput
