import { useLocation } from "@reach/router"
import { Formik } from "formik"
import React, { useMemo, useRef } from "react"

import FormProductOptions from "./FormProductOptions"
import { Color } from "../../../constants/V2/color"
import {
  WEB_TO_LEAD_FORM_IDS,
  WEB_TO_LEAD_INITIAL_VALUES,
  WEB_TO_LEAD_VALIDATION_SCHEMA,
  ESTIMATED_TURNOVER_OPTIONS,
  YOCO_INTENTION_OPTIONS,
} from "../../../constants/V2/salesForm"
import { trackSegmentEvent } from "../../../utils/analytics"
import { getWebToCaseFormSelectedProducts } from "../../../utils/salesForm"
import PillButton from "../../elements/V2/Buttons/PillButton"
import Captcha from "../../elements/V2/Inputs/Captcha"
import DropdownInput from "../../elements/V2/Inputs/DropdownInput"
import PhoneNumberInput from "../../elements/V2/Inputs/PhoneNumberInput"
import TextInput from "../../elements/V2/Inputs/TextInput"
import Typography from "../../elements/V2/Typography"

interface Props {
  preselectedProduct?: string
  inModalId?: string
}

const WebToLeadForm = ({ preselectedProduct, inModalId }: Props) => {
  const location = useLocation()

  const getInitialValues = useMemo(() => {
    let initialValues = WEB_TO_LEAD_INITIAL_VALUES
    if (preselectedProduct) {
      const newValues = {
        [preselectedProduct]: true,
      }
      initialValues = { ...initialValues, ...newValues }
    }
    return initialValues
  }, [preselectedProduct])

  const formElement: React.MutableRefObject<HTMLFormElement | null> =
    useRef(null)

  const submitForm = (values: typeof WEB_TO_LEAD_INITIAL_VALUES) => {
    if (formElement.current) {
      trackSegmentEvent("website_contact_sales_form_submit", {
        name: `${values[WEB_TO_LEAD_FORM_IDS.first_name]} ${
          values[WEB_TO_LEAD_FORM_IDS.last_name]
        }`,
        email: values[WEB_TO_LEAD_FORM_IDS.email],
        estimated_monthly_turnover: values[WEB_TO_LEAD_FORM_IDS.turnover],
        interested_product: getWebToCaseFormSelectedProducts(values),
        not_sure_about_products: values[WEB_TO_LEAD_FORM_IDS.unsure],
        intention: values[WEB_TO_LEAD_FORM_IDS.intention],
      })

      formElement.current.submit()
    }
  }

  let returnUrl = `${location.href}?form_success=true`

  if (inModalId) {
    returnUrl += `&${process.env.GATSBY_MODAL_OPEN_QUERY_PARAM}=${inModalId}`
  }

  return (
    <form
      ref={formElement}
      action={process.env.GATSBY_WEB_TO_LEAD_POST_URL}
      method="POST"
      className="flex flex-col justify-center"
      data-test-id="web-to-lead-form"
    >
      {/* TODO: figure out a better way to have form managed by formik and still populating hidden UTM fields */}
      <input
        name="lead_source"
        id={WEB_TO_LEAD_FORM_IDS.lead_source}
        type="hidden"
        value="website_callback"
      />
      <input
        type="hidden"
        name="Origin"
        id={WEB_TO_LEAD_FORM_IDS.origin}
        value="website_callback"
      />
      <input
        type="hidden"
        name="company"
        id={WEB_TO_LEAD_FORM_IDS.company}
        value="[not provided]"
      />
      <input
        type="hidden"
        name={WEB_TO_LEAD_FORM_IDS.temp_source}
        id={WEB_TO_LEAD_FORM_IDS.temp_source}
        value="yoco_com_sales_form"
      />

      <input
        id={WEB_TO_LEAD_FORM_IDS.utmSource}
        maxLength={255}
        name={WEB_TO_LEAD_FORM_IDS.utmSource}
        size={20}
        type="hidden"
      />
      <input
        id={WEB_TO_LEAD_FORM_IDS.utmMedium}
        maxLength={255}
        name={WEB_TO_LEAD_FORM_IDS.utmMedium}
        size={20}
        type="hidden"
      />
      <input
        id={WEB_TO_LEAD_FORM_IDS.utmTerm}
        maxLength={255}
        name={WEB_TO_LEAD_FORM_IDS.utmTerm}
        size={20}
        type="hidden"
      />
      <input
        id={WEB_TO_LEAD_FORM_IDS.utmCampaign}
        maxLength={255}
        name={WEB_TO_LEAD_FORM_IDS.utmCampaign}
        size={20}
        type="hidden"
      />
      <input
        id={WEB_TO_LEAD_FORM_IDS.utmContent}
        maxLength={255}
        name={WEB_TO_LEAD_FORM_IDS.utmContent}
        size={20}
        type="hidden"
      />
      <input
        name={WEB_TO_LEAD_FORM_IDS.callOutcome}
        type="hidden"
        value="New call request"
      />

      <Formik
        initialValues={getInitialValues}
        validationSchema={WEB_TO_LEAD_VALIDATION_SCHEMA}
        onSubmit={submitForm}
        validateOnChange
        enableReinitialize
      >
        {({ handleSubmit, errors, touched }) => (
          <>
            {process.env.GATSBY_RECAPTCHA_ENABLED === "true" && (
              <input
                type="hidden"
                name="captcha_settings"
                value={`{"keyname":"${
                  process.env.GATSBY_RECAPTCHA_SALESFORCE_KEY_NAME
                }","fallback":"true","orgId":"${
                  process.env.GATSBY_WEB_TO_LEAD_SALESFORCE_OID
                }","ts":${new Date().getTime()}}`}
              />
            )}
            <input type="hidden" name="oid" value={WEB_TO_LEAD_FORM_IDS.oid} />
            <input type="hidden" name="retURL" value={returnUrl} />
            <Typography
              color={Color.Black}
              text="1. Tell us more about yourself"
              font="grotesk"
              size="body-lg"
              weight="medium"
              className="mb-16"
            />
            <div className="grid gap-16 sm:grid-cols-2">
              <TextInput
                required
                type="text"
                maxLength={40}
                label="Name"
                placeholder="Your first name"
                data-test-id="first-name"
                id={WEB_TO_LEAD_FORM_IDS.first_name}
                name={WEB_TO_LEAD_FORM_IDS.first_name}
              />

              <TextInput
                required
                type="text"
                maxLength={80}
                label="Email"
                placeholder="youremail@example.com"
                data-test-id="email"
                id={WEB_TO_LEAD_FORM_IDS.email}
                name={WEB_TO_LEAD_FORM_IDS.email}
              />

              <TextInput
                required
                type="text"
                maxLength={80}
                label="Surname"
                placeholder="Your last name"
                data-test-id="last-name"
                id={WEB_TO_LEAD_FORM_IDS.last_name}
                name={WEB_TO_LEAD_FORM_IDS.last_name}
              />

              <PhoneNumberInput
                required
                label="Phone"
                placeholder="Your phone number"
                data-test-id="phone"
                id={WEB_TO_LEAD_FORM_IDS.phone}
                name={WEB_TO_LEAD_FORM_IDS.phone}
              />
            </div>
            <div className="my-16">
              <DropdownInput
                required
                data-test-id="monthly-turnover"
                id={WEB_TO_LEAD_FORM_IDS.turnover}
                name={WEB_TO_LEAD_FORM_IDS.turnover}
                options={ESTIMATED_TURNOVER_OPTIONS}
                placeholder="Estimated monthly turnover with Yoco"
              />
            </div>
            <Typography
              color={Color.Black}
              text="2. How do you see yourself using Yoco?"
              font="grotesk"
              size="body-lg"
              weight="medium"
              className="mb-16"
            />
            <DropdownInput
              required
              className="mb-16"
              data-test-id="intention"
              placeholder="Select an option"
              options={YOCO_INTENTION_OPTIONS}
              id={WEB_TO_LEAD_FORM_IDS.intention}
              name={WEB_TO_LEAD_FORM_IDS.intention}
            />

            <FormProductOptions
              errors={errors}
              preselectedProduct={preselectedProduct}
              touched={touched}
            />
            <Captcha id="webToLeadCaptcha" className="mt-16" />
            <div className="mt-32">
              <PillButton
                color={Color.Blue}
                onClick={handleSubmit}
                text="Submit"
                size="large"
                style="bordered"
                data-test-id="submit"
                overrideTextColor={Color.Black}
              />
            </div>
          </>
        )}
      </Formik>
    </form>
  )
}

export default WebToLeadForm
